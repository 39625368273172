<template>
    <SmartTable url="/security/users/index" base_path="/security/users">
        <template v-slot:td-verifiedflag="slotProps">
            <td v-if="slotProps.values.field.is_visible_for_index">
                <div class="text-white d-flex flex-column justify-content-center align-items-start align-content-center flex-nowrap">
                    <span v-if="slotProps.values.item.verifiedflag === 'Si'" class="badge pill bg-success mb-1" style="font-size: 0.9em;">ATTIVO</span>

                    <span v-if="slotProps.values.item.verifiedflag === 'No'" class="badge pill bg-danger mb-1" style="font-size: 0.9em;">NON ATTIVO</span>
                </div>
            </td>
        </template>
    </SmartTable>
</template>

<script>
 import SmartTable from "@/view/components/SmartTable.vue";
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

 export default {
     components: {
         SmartTable
     },
     mounted() {
         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Sicurezza", route: "/security/users/index" },
             { title: "Utenti" }
        ]);
    },
 };
</script>
